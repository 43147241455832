/* eslint-disable react/require-default-props */
import moment from 'moment';
import React, { useState, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';

import { paths } from '../utils/routing';
import { breakpointMedium, cardBase, smallText, unit } from '../styles';
import * as GetAppointmentsTypes from 'data-layer/queries/__graphql__/GetAppointments';
import { urlManager } from 'utils/urlManager';
import UserPic from './UserPic';
import Location from './Location';
import { CardTelemedInfo } from './CardTelemedInfo';
import { AppointmentEditBlock } from './AppointmentEditBlock';
import { config, getAppintmentStart, ClientContext } from 'utils';
import CardDropdown, { ICardDropdownItemProps } from './CardDropdown';
import { ReactComponent as AttachFileIcon } from '../assets/attach-file.svg';

// import { ReactComponent as IconHeart } from '../assets/icon-heart.svg';
import { ReactComponent as IconRedo } from '../assets/icon-redo.svg';

import { ITheme, TCardVariant } from '../data-layer/types';
import { openWidget } from './WidgetModal';
import { getClientDocumentByTokenAndUserId } from 'utils/file';
import { Spinner } from './Spinner';
import { Field } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';
import { IconButton } from './shared/IconButton';

interface AppointmentBlockProps {
  appointmentData: GetAppointmentsTypes.GetAppointments_getAppointmentHistory_appointments;
  // eslint-disable-next-line react/require-default-props
  variant?: TCardVariant;
  refetchFn?: (() => unknown) | undefined;
  extraFields?: Field[] | undefined;
}

export const AppointmentBlock: React.FC<AppointmentBlockProps> = React.memo(
  ({ appointmentData, variant, refetchFn, extraFields }: AppointmentBlockProps) => {
    const clientContext = useContext(ClientContext);
    const { t } = useTranslation();
    const [dropdown, setDropdown] = useState(false);
    // const [favorite, setFavorite] = useState(Math.random() > 0.5); // TODO: finish it
    const {
      appointment,
      resource,
      taxonomy,
      id,
      telemedData,
      businessID,
      extraFields: appointmentExtraFields,
      client_appear,
    } = appointmentData;
    const { start } = appointment;
    const startDate = moment.utc(start).calendar(null, {
      sameElse: clientContext.lang.dateFormat,
    });
    const queryString = urlManager.getQueryString();
    const cardVariant: TCardVariant = telemedData?.joinUrl ? 'telemed' : variant;
    const isEditBlockVisible = (['future', 'telemed'] as TCardVariant[]).includes(cardVariant);
    const appStart = getAppintmentStart(appointment, clientContext.timezone);

    const general_info = clientContext.generalInfo;
    const address = (general_info?.address?.[0]?.address || '').replace(',', ' ');
    const businessTitle = useMemo(()=> `${general_info?.name || ''}, ${address}`, [general_info]);

    function onDropdownShow() {
      setDropdown(true);
    }

    function onDropdownHide() {
      setDropdown(false);
    }

    // function onFavoriteClick() {
    //   setFavorite(!favorite);
    // }

    function onRedoClick() {
      openWidget({
        business: businessID,
        screen: 'time',
        resourceId: resource.id,
        taxonomy: taxonomy.id,
      });
    }

    function getWorkerIconSrc() {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return resource?.icon_url
        ? `${config.REACT_APP_CDN_WORKER_IMG || ''}${resource?.icon_url}`
        : '';
    }

    const dropdownItems: ICardDropdownItemProps[] = [
      // TODO: Faivorite apps
      // { icon: IconHeart, onClick: onFavoriteClick, selected: favorite },
      { icon: IconRedo, onClick: onRedoClick },
    ];

    const dateText = [startDate];

    if (cardVariant === 'telemed') {
      dateText.push(t('components.appointmentBlock.telemed'));
    } else {
      // TODO: push cabinet number
    }

    const fileExtraFields = extraFields?.filter((field) => {
      return (
        field.field.fieldType === 'FILE' && field.field.availableInClientCabinet && field.field.active
      );
    });
    const fileId = appointmentExtraFields?.find(
      (appointmentExtraField) =>
        fileExtraFields?.find(
          (extraField) => appointmentExtraField.fieldID === extraField.field.id
        )
    )?.value;

    const [fileIsDownloading, setFileIsDownloading] = useState(false);
    
    return (
      <Card variant={cardVariant} theme={clientContext.theme} clientAppear={client_appear}>
        <CardDropdown
          visible={dropdown}
          onShow={onDropdownShow}
          onHide={onDropdownHide}
          items={dropdownItems}
        />
        {general_info?.showExtraFieldsInClientCabinet && fileId && (
          <Wrapper>
            <IconButton
              iconSize={3}
              disabled={fileIsDownloading}
              onClick={() => {
                setFileIsDownloading(true);
                void getClientDocumentByTokenAndUserId(
                  clientContext.token,
                  clientContext.user,
                  fileId
                ).then(() => setFileIsDownloading(false))
                .catch((e) => {
                  setFileIsDownloading(false);
                  console.error(e);
                });
              }}
            >
              {fileIsDownloading ? (
                <Spinner theme={clientContext.theme}/>
              ) : (
                <AttachFileIconStyled theme={clientContext.theme} />
                )}
            </IconButton>
          </Wrapper>
        )}
        <StyledLink to={`${paths.appointmentPath(id)}?${queryString}`}>
          <CardDate theme={clientContext.theme}>{dateText.join(', ')}</CardDate>
          <CardTitle theme={clientContext.theme} title={taxonomy?.alias || ''}>
            {taxonomy?.alias || ''}
          </CardTitle>
          <CardResource theme={clientContext.theme}>
            <CardResourcePic src={getWorkerIconSrc()} size={50} />
            {clientContext.getResourceName(resource)}
          </CardResource>
          {cardVariant === 'future' &&
            <Location title={businessTitle} />
          }
          {cardVariant === 'telemed' && <CardTelemedInfo appointmentStart={appStart} />}
        </StyledLink>
        {isEditBlockVisible && (
          <AppointmentEditBlock
            refetchFn={refetchFn}
            css="card"
            appointmentData={appointmentData}
          />
        )}
      </Card>
    );
  },
);

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

interface ICardProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: TCardVariant;
  theme: ITheme;
  clientAppear: string;
}

const Card = styled('div')(cardBase, ({ variant, theme, clientAppear }: ICardProps) => {
  let background;
  let paddingTop = unit * 3;
  switch (variant) {
    case 'past':
      background = clientAppear !== 'NO_APPEAR' ? theme.pastItemBackgroundColor : theme.patientNoAppearColor;
      paddingTop = unit * 2;
      break;
    case 'telemed':
      background = theme.telemedItemBackgroundColor;
      break;
    default:
      background = theme.itemBackgroundColor;
      break;
  }
  return {
    background,
    position: 'relative',
    boxShadow: '3px 3px 6px 3px rgb(12, 6, 21, .09)',
    borderRadius: unit * 1.5,
    padding: `${paddingTop}px ${unit * 2}px ${unit * 2.4}px`,
  };
});

const CardTitle = styled('h3')((props: { theme: ITheme }) => ({
  color: props.theme.textColor,
  lineHeight: '23px',
  [`@media screen and (min-width: ${breakpointMedium + 1}px)`]: {
    fontWeight: 900,
  },
  [`@media screen and (max-width: ${breakpointMedium}px)`]: {
    fontWeight: 900,
    fontSize: unit * 2,
  },
}));

const CardDate = styled('div')(smallText, (props: { theme: ITheme }) => ({
  fontSize: 16,
  color: props.theme.textColor,
  overflow: 'hidden',
  fontWeight: 500,
  [`[dir=\'ltr\'] &`]: {
    paddingRight: unit,
  },
  [`[dir=\'rtl\'] &`]: {
    paddingLeft: unit,
  },
}));

const CardResource = styled('h5')((props: { theme: ITheme }) => ({
  color: props.theme.textColor,
  display: 'flex',
  alignItems: 'center',
  margin: 0,
}));

const CardResourcePic = styled(UserPic)({
  [`[dir=\'ltr\'] &`]: {
    marginRight: unit * 1.5,
  },
  [`[dir=\'rtl\'] &`]: {
    marginLeft: unit * 1.5,
  },
});

const StyledLink = styled(Link)({
  color: 'inherit',
  textDecoration: 'inherit',
  display: 'block',
  '& > *:not(:last-child)': {
    marginBottom: unit * 2,
  },
});

const AttachFileIconStyled = styled(AttachFileIcon)(
  (props: { theme: ITheme }) => ({
    path: {
      fill: props.theme.mainColor,
      stroke: props.theme.mainColor,
    },
  })
);

const Wrapper = styled('div')({
  position: 'absolute',
  marginTop: '60px',
  ['[dir=\'ltr\'] &']: {
    right: unit,
  },
  ['[dir=\'rtl\'] &']: {
    left: unit,
  },
});
