import React, { useState, useMemo, useContext, Fragment, useEffect } from 'react';
import _ from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';

import {
  PageContainer,
  AppointmentBlock,
  BottomFilter,
  IFilterItem,
  SmallStartRecord,
  ScrollBlock,
} from '../components';
import {
  GetAppointmentsVariables,
  GetAppointments,
  GetAppointments_getAppointmentHistory_appointments,
} from 'data-layer/queries/__graphql__/GetAppointments';
import { GET_APPOINTMENTS } from 'data-layer/queries';
import { urlManager } from 'utils/urlManager';
import styled from '@emotion/styled';
import { breakpointMedium, breakpointLarge, breakpointSmall, cardBase, mediumText, size, unit } from '../styles';
import * as Moment from 'moment';
import { extendMoment, DateRange } from 'moment-range';

import { IAppFilter, ITheme } from 'data-layer/types';
import { ClientContext, getUTCDateForCompare, usePostMessage } from 'utils';
import {
  getApiFilterValues,
  resourceAppFilter,
  serviceAppFilter,
  dateAppFilter, statusAppFilter,
} from 'data-layer/helpers';
import { MedMeAPI } from '@gbooking/ts-sdk';
import { BusinessId, Field } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';

const moment = extendMoment(Moment);

type HomeScreenProps = RouteComponentProps;

const HomeScreen: React.FC<HomeScreenProps> = () => {
  const clientContext = useContext(ClientContext);
  const { businessId, theme } = clientContext;

  const [appHistoryLoaded, setAppHistoryLoadedStatus] = useState(false);
  const LOAD_APP_SIZE = 6;
  const [selectedFilter, setAppointmentFilter] = useState(IAppFilter.all);
  const [pastAppointmentsLimit, setPastAppointmentLimit] = useState(LOAD_APP_SIZE - 1);
  const [selectedMonth, setSelectedMonth] = useState<IFilterItem[]>([]);
  const [selectedYear, setSelectedYear] = useState<IFilterItem[]>([]);
  const [offset, setOffset] = useState(0)
  const [selectedResources, setSelectedResource] = useState<IFilterItem[]>([]);
  const [selectedServices, setSelectedServices] = useState<IFilterItem[]>([]);
  const selectedResourcesKey = selectedResources.map((r: IFilterItem) => r.key);
  const selectedServicesKey = selectedServices.map((r: IFilterItem) => r.key);

  const filterChange = (key: IAppFilter) => {
    setAppointmentFilter(key);
  };
  const handleMoreClick = () => {
    setOffset(pastAppointmentsLimit)
    setPastAppointmentLimit(pastAppointmentsLimit + LOAD_APP_SIZE);
  };
  const { t } = useTranslation();
  const appData = useQuery<GetAppointments, GetAppointmentsVariables>(GET_APPOINTMENTS, {
    variables: {
      clientID: clientContext.clientId,
      user: clientContext.user || '',
      token: clientContext.token || '',
      businessID: urlManager.getBusinessId(),
      networkID: urlManager.getNetworkId(),
      contractID: urlManager.getContractId(),
      contractExtraId: urlManager.getContractExtraId(),
    },
    skip: !clientContext.clientId,
    fetchPolicy: 'cache-and-network',
    onCompleted () {
      setAppHistoryLoadedStatus(true);
    },
  });

  const refetchAppointments = () => appData.refetch();
  usePostMessage({ refetchApp: refetchAppointments });
  const now = moment.utc();

  type AppointmentT = GetAppointments_getAppointmentHistory_appointments | null
  const appointmentDate = (app: AppointmentT) =>
    app ? getUTCDateForCompare(app.appointment.start, clientContext.timezone) : 0;

  const appointments: AppointmentT[] =
    (appData.data?.getAppointmentHistory.appointments || [])
      .sort((a: AppointmentT, b: AppointmentT) =>
        +new Date(appointmentDate(b)) - +new Date(appointmentDate(a))
      );

  const isFutureApp = (app: AppointmentT) => !!app &&
    moment(appointmentDate(app))
      .add(app.appointment.duration, 'minutes')
      .isAfter(now);

  const isPastApp = (app: AppointmentT) => !!app &&
    moment(appointmentDate(app))
      .add(app.appointment.duration, 'minutes')
      .isBefore(now);

  const isTelemedApp = (app: AppointmentT) => !!app?.telemedData?.joinUrl;

  const filterRanges = useMemo((): DateRange[] => {
    const ranges: DateRange[] = [];
    if (selectedYear.length) {
      if (selectedMonth.length) {
        selectedMonth
          .map((m) => +m.key)
          .forEach((months) => {
            selectedYear
              .map((y) => +y.key)
              .forEach((year) => {
                const startDate = moment([year, months - 1]);
                const endDate = moment(startDate).endOf('month');
                ranges.push(moment.range(startDate, endDate));
              });
          });
      } else {
        selectedYear
          .map((y) => +y.key)
          .forEach((year) => {
            const startDate = moment([year, 0]);
            const endDate = moment(startDate).endOf('year');
            ranges.push(moment.range(startDate, endDate));
          });
      }
    }
    return ranges;
  }, [selectedYear, selectedMonth]);

  const filteredApps = useMemo(
    () =>
      appointments
        .filter((a) => !!a && dateAppFilter(a, filterRanges))
        .filter((a) => statusAppFilter(a))
        .filter((a) => resourceAppFilter(a, selectedResources))
        .filter((a) => serviceAppFilter(a, selectedServices)),
    [appointments, filterRanges, selectedResources, selectedServices],
  );

  const cleanFilters = () => {
    setSelectedMonth([]);
    setSelectedYear([]);
    setSelectedResource([]);
    setSelectedServices([]);
  };

  function FiltersBlock () {
    const { resources, taxonomies } = getApiFilterValues({ appointments, selectedResourcesKey, selectedServicesKey });
    return <BottomFilter params={{
      selectedMonth,
      selectedYear,
      selectedMonthChange: (v: any) => setSelectedMonth(v),
      selectedYearChange: setSelectedYear,
      selectedResources,
      selectedResourcesChange: setSelectedResource,
      availableResources: resources,
      availableServices: taxonomies,
      selectedServices,
      selectedServicesChange: setSelectedServices,
      cleanFilters,
    }} />;
  }

  const [extraFields, setExtraFields] = useState<Field[]>([]);
  useEffect(() => {
    const params = {
      business: {
        id: businessId,
      },
    } as BusinessId;
    void MedMeAPI.field
      .getExtraFields(params)
      .then((res: Field[]) => setExtraFields(res));
  }, []);
  function appToRender(app: AppointmentT) {
    return !!app && <AppointmentBlock
      key={app.id}
      variant={
        isPastApp(app) ? 'past'
          : isTelemedApp(app) ? 'telemed'
            : isFutureApp(app) ? 'future'
              : undefined
      }
      appointmentData={app}
      refetchFn={refetchAppointments}
      extraFields={extraFields}
    />
  }

  function renderAppointments () {
    const futureApps = filteredApps.filter(isFutureApp);
    let pastApps = filteredApps.filter(isPastApp);
    if(clientContext.businessInfo?.backoffice_configuration.hideNotComeRecordsInClientCabinet) {
      pastApps = pastApps.filter((a) => a?.client_appear !== 'NO_APPEAR');
    }
    futureApps.sort((a: AppointmentT, b: AppointmentT) =>
      +new Date(appointmentDate(a)) - +new Date(appointmentDate(b))
    )
    const blocks = [{
      title: 'future',
      data: futureApps.map(appToRender).filter(a => !!a)
    }, {
      title: 'past',
      data: pastApps.map(appToRender).filter(a => !!a)
    }
    ]
    const bothEmpty = !_.some(blocks, (c) => !!c.data.length)
    return <>
      <SmallStartRecordStyled />
      <FiltersBlock />
      {bothEmpty
        ? <NoResults>{t('components.bottomFilters.noData')}</NoResults>
        : blocks.map(({ title, data }) => data.length > 0
          ?
          <Fragment key={`block_app_${title}`}>
            <Title theme={theme}>{t(`screens.home.${title}`)}</Title>
            <AppointmentsWrapper key={`app_${title}`} theme={theme}> {data} </AppointmentsWrapper>
          </Fragment>
          : ''
        )}
    </>;
  }

  return (
    <PageContainerStyled
      layoutClassName={'home'}
      title={t('components.topNav.myRecords')}
      topNav
    >
      <ScrollBlock>
        {renderAppointments()}
      </ScrollBlock>
    </PageContainerStyled>
  );
};
export default HomeScreen;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const SmallStartRecordStyled = styled(SmallStartRecord)({
  marginBottom: `${unit * 3}px`
});

const AppointmentsWrapper = styled('div')((props: { theme: ITheme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  margin: `auto 0 auto -${size.cardOffset}px`,
  paddingTop: unit,
  '& > *': {
    ...cardBase,
  },
  '& > button': {
    color: props.theme.mainColor,
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    marginTop: unit * 2,
  },
}));

const CardBlock = styled('div')(mediumText, (props: { theme: ITheme }) => ({
  position: 'relative',
  color: props.theme.textColor,
  padding: `${unit * 3}px ${0}px ${unit * 1.5}px ${unit * 2}px`,
}));

const NoResults = styled('div')({
  margin: `${unit * 3}vh auto 0`,
  textAlign: 'center',
});

const PageContainerStyled = styled(PageContainer)({
  '.ScrollbarsCustom': {
    width: `calc(100% + ${unit * 6}px) !important`,
    marginLeft: `-${unit * 3}px`,
    marginRight: `-${unit * 3}px`,
    [`@media screen and (max-width: ${breakpointSmall}px)`]: {
      marginLeft: `-${unit * 1.5}px`,
      marginRight: `-${unit * 1.5}px`,
    },
  },
  '.ScrollbarsCustom-Content': {
    paddingLeft: `${unit * 3}px !important`,
    paddingRight: `${unit * 3}px !important`,
    [`@media screen and (max-width: ${breakpointSmall}px)`]: {
      paddingLeft: `${unit * 1.5}px !important`,
      paddingRight: `${unit * 1.5}px !important`,
    },
  },
});

const Title = styled('h3')((props: { theme: ITheme }) => ({
  //color: props.theme.mainColor,
  fontWeight: 900,
  fontSize: 20,
  lineHeight: 1.15,
  marginBottom: `${unit}px`,
  marginTop: `${unit * 2}px`,
  [`@media screen and (max-width: ${breakpointMedium}px)`]: {
    marginLeft: 0,
    marginRight: 0,
  },
  [`@media screen and (max-width: ${breakpointSmall}px)`]: {
    [`[dir="ltr"] &`]: {
      marginLeft: unit,
    },
    [`[dir="rtl"] &`]: {
      marginRight: unit,
    },
  },
}));
