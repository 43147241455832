import {
  ApolloClient,
  HttpLink,
  NormalizedCacheObject,
  InMemoryCache,
  defaultDataIdFromObject,
  IdGetterObj,
} from 'apollo-boost';
import { persistCache } from 'apollo-cache-persist';
import { PersistentStorage, PersistedData } from 'apollo-cache-persist/types';
import { resolvers } from './resolvers';
import { cacheRedirects } from './cacheRedirects';
import { urlManager } from 'utils/urlManager';
import { GET_NETWORK_ID_AND_BUSINESS_ID } from './queries/getNetworkBusinessID';
import { getNetworkIDAndBusinessID } from './queries/__graphql__/getNetworkIDAndBusinessID';
import { GET_LAST_ENTER_DATE } from './queries/getLastEnterDate';
import { getLastEnterDate } from './queries/__graphql__/getLastEnterDate';
import moment from 'moment';

// Added for autologout in iframe
const clearClientDataWhenTimeout = async (client: ApolloClient<NormalizedCacheObject>) => {
  const timeout = parseInt(urlManager.getTimeout());
  if (timeout) {
    try {
      const getLastEnterDate = client.cache.readQuery({ query: GET_LAST_ENTER_DATE }) as getLastEnterDate;
      const now = moment.utc();
      if (Math.round(moment.duration(now.diff(getLastEnterDate.lastEnterDate)).asSeconds()) > timeout) {
        void client.clearStore();
        localStorage.clear();
      }
    // eslint-disable-next-line no-empty
    } catch (e) { }
  }
}

// Added for autologout in iframe
const clearClientDataWhenBusinessOrNetworkChanged = async (client: ApolloClient<NormalizedCacheObject>, newBusinessID: string, newNetworkID: string) => {
  if (urlManager.getClearClientData()) {
    try {
      const data = client.cache.readQuery({ query: GET_NETWORK_ID_AND_BUSINESS_ID }) as getNetworkIDAndBusinessID;
      if ((newBusinessID && newBusinessID !== data?.businessID) || (newNetworkID && newNetworkID !== data?.networkID)) {
        void client.clearStore();
        localStorage.clear();
      }
      // eslint-disable-next-line no-empty
    } catch (e) { }
  }
}

export type Client = ApolloClient<NormalizedCacheObject>;
interface CustomIdGetterObj extends IdGetterObj {
  shortId?: string;
}
export const createApolloClient = async (): Promise<Client> => {
  const cache = new InMemoryCache({
    cacheRedirects,
    dataIdFromObject: (object: CustomIdGetterObj) => {
      // eslint-disable-next-line no-underscore-dangle
      switch (object.__typename) {
        case 'AppointmentClient':
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          return `AppointmentClient:${object.shortId}`;
        default:
          return defaultDataIdFromObject(object);
      }
    },
  });
  const storage = localStorage as PersistentStorage<PersistedData<NormalizedCacheObject>>;
  await persistCache({ cache, storage });
  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    cache,
    link: new HttpLink({ uri: 'http://localhost:8080/no-graphql' }),
    resolvers,
    // it brings Apollo DevTools in Chrome to life since it requires a schema,
    // but we don't have a GraphQL server:
    // https://github.com/apollographql/apollo-client-devtools/issues/228
    // TODO: provide the full schema in development here for our schema entities
    // to show up in the Apollo DevTools playground
    // eslint-disable-next-line
    typeDefs: {} as any,
  });

  const newBusinessID = urlManager.getBusinessId();
  const newNetworkID = urlManager.getNetworkId();
  await clearClientDataWhenTimeout(client);
  await clearClientDataWhenBusinessOrNetworkChanged(client, newBusinessID, newNetworkID);

  const initData = {
    businessID: newBusinessID || '',
    networkID: newNetworkID || '',
    lastEnterDate: moment.utc().toISOString(),
  };
  cache.writeData({ data: initData });
  return client;
};
